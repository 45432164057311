import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Box,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getGuestsEventsDetailsApi } from "../../api";

const GuestsEventRow = ({ row, index,username,userId }) => {
  const token = useSelector((state) => state?.user?.user?.token);
  // console.log("ev1",row)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  const handleRowClick = async (id) => {
    try {
      setLoading(true);
     const rowData = await getGuestsEventsDetailsApi({token,id})
    //  console.log("gg",response);
     
     setLoading(false);
      navigate(`/dashboard/guests/all-guests/guests-information/guests-events/events-details/${id}`, {
        state: { rowData, row, username, userId },
      });
    } catch (error) {
      setLoading(false);
      console.error("Error fetching event data:", error);
    } 
  };

  return (
    <TableRow key={row?._id}>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              fontFamily: "Rosario",
              fontWeight: 500,
              fontSize: "17px",
            }}
          >
            {index + 1 || ""}
          </div>
        </Box>
      </TableCell>

      <TableCell
        onClick={()=>handleRowClick(row?.event_id)}
        sx={{
          color: "#1877F2",
          fontFamily: "Rosario",
          textDecoration: "underline",
          fontWeight: 500,
          fontSize: "17px",
        }}
      >
        <div style={{ cursor: "pointer" }}>
          {row?.event_name || "-"}
        </div>
      </TableCell>

      <TableCell
        sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
      >
        <div>
          {row?.address || ""}
          {/* Add more address fields here if needed */}
        </div>
      </TableCell>

      <TableCell
        sx={{
          fontFamily: "Rosario",
          fontWeight: 500,
          fontSize: "17px",
        }}
      >
        <div>
          {row?.date
            ? new Date(row?.date || "-").toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : "-"}
        </div>
      </TableCell>

      <TableCell
        sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
      >
        <div>{row?.total_attended_users || 0}</div>
      </TableCell>

      <TableCell
        sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
      >
        <div>{0}</div>
      </TableCell>
    </TableRow>
  );
};

export default GuestsEventRow;
