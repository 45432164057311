import React, { useState } from "react";
import { TableRow, TableCell, Box, Avatar, Menu, MenuItem } from "@mui/material";

import CharMenuIcon from '../assest/charmenuicon.svg'
import { useNavigate } from "react-router-dom";

const ChefRow = ({row,deleteUser,suspendUser}) => {
  // console.log("chef",row);
  

const navigate=useNavigate();
 const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate=()=>{
    navigate(`/dashboard/chefs/all-chefs/chefs-information/${row?.chef_id}`,{ state: row  })
    handleClose();
  }
 

  return (
    <>
    
        <TableRow >
          <TableCell>
            <Box 
              onClick={()=>navigate(`/dashboard/chefs/all-chefs/chefs-information/${row?.chef_id}`,{ state:  row  })}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // flexWrap: "wrap",
                gap: "10px",
                cursor:"pointer"
              }}
            >
              <Avatar
                alt={row?.full_name}
                src={row?.profile_picture}
                sx={{ borderRadius: "13px" }}
              />
              <div style={{ 
                 color: "#1877F2",
                 fontFamily: "Rosario",
                 textDecoration: "underline",
                 fontWeight: 500,
                 fontSize: "17px",
                 }}>
                {row?.full_name||"-"}
              </div>
            </Box>
          </TableCell>

          <TableCell
        
            sx={{
            
              fontFamily: "Rosario",
           
              fontSize: "17px",
            }}
          >
            <div >{row?.email||"-"}</div>
          </TableCell>

          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
          >
            <div>{row?.experience_years||'0'}</div>
          </TableCell>

          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
          >
            <div>{row?.no_of_events||'0'}</div>
          </TableCell>

          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
          >
            <div>{row?.average_rating ?  parseFloat(row?.average_rating).toFixed(2):0}</div>
        
          </TableCell>
          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
          >
            <div>{row?.earnings||"0"}</div>
          </TableCell>

          <TableCell
            sx={{
              fontFamily: "Rosario",
              fontWeight: 500,
              fontSize: "17px",
             
            }}
          >
            <div>{
              row?.joined_date ? 
              new Date(row?.joined_date || "-").toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
              :"-"
              }</div>
          </TableCell>

          <TableCell
            sx={{
              fontFamily: "Rosario",
              fontWeight: 500,
              fontSize: "17px",
             
            }}
          >
            <div>{row?.subs||"-"}</div>
          </TableCell>


          <TableCell
            sx={{
              fontFamily: "Rosario",
              fontWeight: 500,
              fontSize: "17px",
              color:row?.is_banned ? "#EA1D26":"#009048"
              
            }}
          >
            <div>{row?.is_banned ? "Suspended":"Active"||'-'}</div>
          </TableCell>

          <TableCell
            onClick={handleNavigate}
            sx={{
                color: "#1877F2",
                fontFamily: "Rosario",
                textDecoration: "underline",
                fontWeight: 500,
                fontSize: "17px",
                 cursor:'pointer'
            }}
          >
            <div>View More</div>
          </TableCell>

          
          <TableCell
           
            sx={{
                color: "#1877F2",
                fontFamily: "Rosario",
                textDecoration: "underline",
                fontWeight: 500,
                fontSize: "17px",
                cursor:'pointer'
            }}
          >
            <div><img src={CharMenuIcon} onClick={handleClick} /></div>
          </TableCell>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            sx={{
                '& .MuiPaper-root': {
                  borderRadius: '25px', 
                  bgcolor: 'white',
                  boxShadow:"none",
                  border: "1px solid #B0B0B0"
                },
              }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
            {/* <MenuItem onClick={handleNavigate} sx={{fontFamily:'Rosario'}}>View User Account</MenuItem> */}
            <MenuItem onClick={suspendUser} sx={{fontFamily:'Rosario'}}>
            {row?.is_banned ? "Resume User Account":"Suspend User Account"}</MenuItem>
            <MenuItem onClick={deleteUser} sx={{fontFamily:'Rosario',color:'#EA1D26',fontWeight:'400'}}>Delete User Account</MenuItem>
        </Menu>


        </TableRow>

        
    
    </>
  );
};

export default ChefRow;
