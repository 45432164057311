import React, { useEffect, useState } from "react";

import Card1 from "../../common/card1/Card1";
import SearchBar from "../../common/searchbar/SearchBar";
import TableCard from "../../common/tableCard/TableCard";
import TableComp from "../../common/tablecomp/TableComp";
import ChefRow from "../../common/tablerow/ChefRow";

import { Link, useLocation, useParams } from "react-router-dom";
import EarningsRow from "../../common/tablerow/EarningsRow";
import EventsRows from "../../common/tablerow/EventsRow";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getChefsEventsApi } from "../../api";
import Loader from "../../common/loader/Loader";
import { TableCell, TableRow } from "@mui/material";

const ChefEvents = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  // const location=useLocation();
  // const allData=location?.state
  // const chefId=location?.state?.chef_id

  const { state } = useLocation();
  // const chefsData = state?.chefsData;

  // console.log("ev", chefsData);

  const { id } = useParams();

  const [totalEventCount, setTotalEventCount] = useState(0);
  const [chefsEventData, setChefsEventData] = useState([]);
  const [chefName, setChefName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [myTimout, setMyTimeOut] = useState();
  const [change, setChange] = useState(false);
  // const [ids,setIds]=useState('')
  const [loading, setLoading] = useState(false);

  const handleChefEvents = async () => {
    try {
      setLoading(true);
      const response = await getChefsEventsApi({ token, id });
      // console.log("op", chefName);
      setChefsEventData(response?.events);

        // extracting name to show for breadCrum
      const firstEvent = response?.events?.[0];
      const extractedChefName = firstEvent?.chef?.user?.full_name || "N/A";
      setChefName(extractedChefName);

      setLoading(false);
      setCurrentPage(response?.page);
      setTotalPage(response?.pages);
      setTotalEventCount(response?.total_count_event || 0);
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  useEffect(() => {
    handleChefEvents();
  }, []);

  const style = {
    minHeight: "95px",
    paddingTop: "22px",
    cursor: "default",
  };
  const titles = [
    { title: "Sr No" },
    { title: "Event Name" },
    { title: "Address" },
    { title: "Date" },
    { title: "Attendees" },
    { title: "Earnings" },
  ];

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/chefs" className="All-color-breakcurm">
      Chef’s
    </Link>,
    <Link
      key="home"
      to="/dashboard/chefs/all-chefs"
      className="All-color-breakcurm"
    >
      Chef’s Details
    </Link>,
    <Link
      key="home"
      to={`/dashboard/chefs/all-chefs/chefs-information/${id}`}
      // /dashboard/chefs/all-chefs/chefs-information/6735ff1591949a92c20cd444
      // /dashboard/chefs/all-chefs/chefs-information/chefs-events/
      className="All-color-breakcurm"
    >
      {chefName|| "N/A"}
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;Events</span>,
  ];
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="bread-crum">
            {breadcrumbItems.map((item, index) => (
              <div className="all-breakcrum" key={index}>
                {item}
                {index < breadcrumbItems.length - 1 && " > "}
              </div>
            ))}
          </div>

          <div className="chef-details-card-container">
            <Card1
              value={totalEventCount || 0}
              text="Total Events  >"
              style={style}
            />
          </div>

          <TableCard>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                padding: "20px",
                fontFamily: "Rosario",
                fontWeight: 600,
                fontSize: "20px",
                gap: "10px",
                marginBottom: "20px",
              }}
            >
              <div>Events Details</div>
              <div>
                <SearchBar />
              </div>
            </div>

            <TableComp title={titles}>
              {chefsEventData?.length > 0 ? (
                chefsEventData?.map((item, idx) => (
                  <EventsRows row={item} index={idx} key={item?._id} />
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={titles.length}
                    align="center"
                    sx={{ fontFamily: "Rosario", fontWeight: "600" }}
                  >
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableComp>
          </TableCard>
        </>
      )}
    </div>
  );
};

export default ChefEvents;
