import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';  // Import from 'react-dom/client' for React 18
import AlertDialog from '../common/modal/ConfirmModal';


const alertDialog = ({ title, description }) => {
  return new Promise((resolve) => {
    const ModalWrapper = () => {
      const [open, setOpen] = useState(true);

      const handleClose = () => {
        setOpen(false);
        cleanup();
        resolve(false); // User canceled
      };

      const handleConfirm = () => {
        setOpen(false);
        cleanup();
        resolve(true); // User confirmed
      };

      const cleanup = () => {
        root.unmount(); // Unmount the component from the root
        document.body.removeChild(container);
      };

      return (
        <AlertDialog
          open={open}
          title={title}
          description={description}
          onClose={handleClose}
          onConfirm={handleConfirm}
        />
      );
    };

    // Create a container div for the modal
    const container = document.createElement('div');
    document.body.appendChild(container);

    // Create the root and render the modal using `createRoot`
    const root = ReactDOM.createRoot(container);
    root.render(<ModalWrapper />);
  });
};

export default alertDialog;
