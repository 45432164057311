export default function formatDate(dateString) {
    if (!dateString) return "N/A"; // Handle missing dates
  
    const date = new Date(dateString); // Parse the ISO string into a Date object
    if (isNaN(date)) return "Invalid Date"; // Handle invalid date formats
  
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' }); // Get full month name
    const year = date.getFullYear();
  
    const ordinal = (day) => {
      if (day > 3 && day < 21) return 'th'; // Handle 11th, 12th, 13th
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
  
    return `${day}${ordinal(day)} ${month}, ${year}`; // Format the date
  }
  