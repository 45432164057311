import React, { useState } from "react";
import TicketDrawer from "./TicketDrawer"; // Import the common drawer
import "./Ticket.css";

const Resolved = ({ resolvedTickets }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const handleTicketClick = (ticket) => {
    setSelectedTicket(ticket);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedTicket(null);
  };

  return (
    <>
      {resolvedTickets.length > 0 ? (
        resolvedTickets.map((ticket) => (
          <div
            className="ticket-container"
            style={{ marginTop: "20px" }}
            key={ticket?._id}
            onClick={() => handleTicketClick(ticket)}
          >
            <div className="ticket-card">
              <div className="ticket-column">
                <div className="ticket-box title">{ticket?.title || "N/A"}</div>
                <div className="ticket-box datess">{ticket.date}</div>
                <div className="ticket-box ticket-description">
                  {ticket?.description || "N/A"}
                </div>
                <div className="ticket-box status-priority-container">
                  <div className="ticket-priority">
                    Priority:{" "}
                    <span style={{ color: "#EA1D26" }}>
                      {ticket?.priority || "High"}
                    </span>
                  </div>
                  <div className="ticket-status">
                    Status:{" "}
                    <span style={{ color: "#009048" }}>
                      {ticket?.status || "Resolved"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div style={{ textAlign: "center" }}>No Resolved Tickets Found</div>
      )}

      {/* Use the common TicketDrawer */}
      <TicketDrawer
        isOpen={isDrawerOpen}
        ticket={selectedTicket}
        onClose={handleCloseDrawer}
        onStatusChange={() => {}} 
      />
    </>
  );
};

export default Resolved;
