import React, { useEffect, useState } from "react";
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import ChefLogo from '../assest/cheflogo.png'
import SideBarImg from '../assest/Side Navigation.png'
import HomeLogo from '../assest/home.svg'
import UserShapeIcon from '../assest/combo_shape.svg'
import BookShapeIcon from '../assest/Combo_shape1.svg'
import UserShapeIconFilled from '../assest/Subtracts.svg'
import "./Main.css";

const Sidebar = ({ isSidebarOpen, toggleSidebar, isMobileView }) => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const navigate = useNavigate();
  const location = useLocation(); 

  // useEffect(() => {
  //   const currentPath = location.pathname;

  //   if (currentPath.includes("/account-management")) {
  //     setActiveTab("account");
  //   } else if (currentPath.includes("/admin-management")) {
  //     setActiveTab("admin");
  //   } else if (currentPath.includes("/settings")) {
  //     setActiveTab("settings")
  //    } else if (currentPath.includes("/content-management")) {
  //       setActiveTab("content-management");
  //   } else {
  //     setActiveTab("dashboard");
  //   }
  // }, [location]); 

  useEffect(() => {
    const currentPath = location.pathname;
  
    if (currentPath.startsWith("/dashboard/account-management")) {
      setActiveTab("account");
    } else if (currentPath.startsWith("/dashboard/admin-management")) {
      setActiveTab("admin");
    } else if (currentPath.startsWith("/dashboard/settings")) {
      setActiveTab("settings");
    } else if (currentPath.startsWith("/dashboard/content-management")) {
      setActiveTab("content-management");
    } else {
      setActiveTab("dashboard");
    }
  }, [location]);

  const handleRoutes = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case "dashboard":
        navigate("/dashboard");
        break;
      case "account":
        navigate("/dashboard/account-management");
        break;
      case "admin":
        navigate("/dashboard/admin-management");
        break;
        case "content":
        navigate("/dashboard/content-management");
        break;
      case "settings":
        navigate("/dashboard/settings");
        break;
      default:
        break;
    }
  };

  return (
    <Drawer
      sx={{
        borderRadius: "0px 24px 24px 0px !important",
        "& .MuiPaper-root": {
          borderRadius: "0px 24px 24px 0px",
          border: "none",
          height: "100vh",
          backgroundColor: "none",
        },
      }}
      anchor="left"
      open={isSidebarOpen || !isMobileView}
      onClose={toggleSidebar}
      variant={isMobileView ? "temporary" : "persistent"}
    >
      <div
        style={{
          width: "244px",
          height: "100vh",
          borderRadius: "0px 24px 24px 0px",
        //   backgroundColor: "#9A031E",
        backgroundImage: `url(${SideBarImg})`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
          paddingLeft: "10px",
        }}
      >
        <List sx={{ marginTop: "40px"}}>
          <img
            src={ChefLogo}
            style={{ width: "140px" ,marginLeft:'40px',}}
            alt="Logo"
          />

          <div style={{marginLeft:'57px',fontSize:"22px",fontFamily:"Rosario",color:'#FFF',marginTop:"10px"}}>Chef’s Table</div>

          {/* Dashboard ListItem */}
          <ListItem
            sx={{
              maxWidth: "230px",
              marginTop: "40px",
              height: "60px",
              cursor: "pointer",
              borderRadius: "18px",
            //   boxShadow: activeTab === "dashboard" ? "5px 0px 0px #6c0215" : "",
              opacity: activeTab === "dashboard" ? "1" : "0.6",
              color: activeTab === "dashboard" ? "white" : "#B0B0B0",
              backgroundColor:
                activeTab === "dashboard" ? "rgba(255, 255, 255, 0.3)" : "transparent",
            }}
            onClick={() => handleRoutes("dashboard")}
          >
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <img src={HomeLogo} style={{ color: "#B0B0B0" }} alt="Home Icon" />
            </ListItemIcon>
            <ListItemText primary="Dashboard" sx={{ fontFamily: "Rosario" }} />
          </ListItem>


            {/*Content Management*/}

            {/* <ListItem
            sx={{
              maxWidth: "230px",
              marginTop: "10px",
              height: "60px",
              cursor: "pointer",
              borderRadius: "18px",
           
              opacity: activeTab === "content-management" ? "1" : "0.6",
              color: activeTab === "content-management" ? "white" : "#B0B0B0",
              backgroundColor:
                activeTab === "content-management" ? "rgba(255, 255, 255, 0.3)" : "transparent",
            }}
            onClick={() => handleRoutes("content")}
          >
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <img src={UserShapeIconFilled} style={{ color: "#B0B0B0" }} alt="Settings Icon" />
            </ListItemIcon>
            <ListItemText primary="Content Management" sx={{ fontFamily: "Rosario" }} />
          </ListItem> */}

          {/* Account Management ListItem */}
          <ListItem
            sx={{
              maxWidth: "230px",
              marginTop: "10px",
              height: "60px",
              cursor: "pointer",
              borderRadius: "18px",
            //   boxShadow: activeTab === "account" ? "5px 0px 0px #6c0215" : "",
              opacity: activeTab === "account" ? "1" : "0.6",
              color: activeTab === "account" ? "white" : "#B0B0B0",
              backgroundColor:
                activeTab === "account" ? "rgba(255, 255, 255, 0.3)" : "transparent",
            }}
            onClick={() => handleRoutes("account")}
          >
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <img src={UserShapeIcon} style={{ color: "#B0B0B0" }} alt="Account Icon" />
            </ListItemIcon>
            <ListItemText primary="Account Management" sx={{ fontFamily: "Rosario" }} />
          </ListItem>

          {/* Admin Management ListItem */}
          <ListItem
            sx={{
              maxWidth: "230px",
              marginTop: "10px",
              height: "60px",
              cursor: "pointer",
              borderRadius: "18px",
            //   boxShadow: activeTab === "admin" ? "5px 0px 0px #6c0215" : "",
              opacity: activeTab === "admin" ? "1" : "0.6",
              color: activeTab === "admin" ? "white" : "#B0B0B0",
              backgroundColor:
                activeTab === "admin" ? "rgba(255, 255, 255, 0.3)" : "transparent",
            }}
            onClick={() => handleRoutes("admin")}
          >
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <img src={BookShapeIcon} style={{ color: "#B0B0B0" }} alt="Admin Icon" />
            </ListItemIcon>
            <ListItemText primary="Admin Management" sx={{ fontFamily: "Rosario" }} />
          </ListItem>

          {/* Settings ListItem */}
          <ListItem
            sx={{
              maxWidth: "230px",
              marginTop: "10px",
              height: "60px",
              cursor: "pointer",
              borderRadius: "18px",
            //   boxShadow: activeTab === "Settings" ? "5px 0px 0px #6c0215" : "",
              opacity: activeTab === "settings" ? "1" : "0.6",
              color: activeTab === "settings" ? "white" : "#B0B0B0",
              backgroundColor:
                activeTab === "settings" ? "rgba(255, 255, 255, 0.3)" : "transparent",
            }}
            onClick={() => handleRoutes("settings")}
          >
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <img src={UserShapeIconFilled} style={{ color: "#B0B0B0" }} alt="Settings Icon" />
            </ListItemIcon>
            <ListItemText primary="Settings" sx={{ fontFamily: "Rosario" }} />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default Sidebar;
