import React from "react";
import Card1 from "../../common/card1/Card1";
import { useNavigate } from "react-router-dom";
import Chef from "../../common/assest/Chef.png";
import TicketLogo from "../../common/assest/ticketManagementLogo.png";
import ContentLogo from "../../common/assest/contentManagementLogo.png";
import ReportLogo from "../../common/assest/reportLogo.png";
import SubscriptionLogo from "../../common/assest/subscriptionLogo.png";
import "./ChefSection.css";

const ChefSection = () => {
  const navigate = useNavigate();

  const style = {
    maxWidth: "300px",
  };
  return (
    <div>
      <div className="chef-section-card-container">
        <Card1
          text="Chef’s Details"
          img={Chef}
          style={style}
          onClick={() => navigate("/dashboard/chefs/all-chefs")}
        />
        <Card1
          text="Ticket Management"
          img={TicketLogo}
          style={style}
          onClick={() => navigate("/dashboard/chefs/ticket-management")}
        />
        <Card1
          text="Content Management "
          img={ContentLogo}
          style={style}
          onClick={() => navigate("/dashboard/chefs/content-management")}
        />
        {/* <Card1
          text="Reports"
          img={ReportLogo}
          style={style}
          onClick={() => navigate("/dashboard/chefs/reports")}
        /> */}
        <Card1
          text="Subscription"
          img={SubscriptionLogo}
          style={style}
          onClick={() => navigate("/dashboard/chefs/subscription-management")}
        />
      </div>
    </div>
  );
};

export default ChefSection;
