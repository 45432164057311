import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Box,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import GradeIcon from "@mui/icons-material/Grade";
import CharMenuIcon from "../assest/charmenuicon.svg";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import StarIcon from "@mui/icons-material/Star";
const ReviewsRow = ({ row, deleteReview,index }) => {
// console.log("afa",row);

    const StarRating = ({ rating }) => {
        const fullStars = Math.floor(rating);
        const decimal = rating - fullStars;
        const stars = [];
    
        for (let i = 0; i < fullStars; i++) {
          stars.push(
            <StarIcon key={i} sx={{ color: "#FFD700", fontSize: "20px" }} />
          );
        }
    
        if (decimal > 0) {
          stars.push(
            <StarHalfIcon
              key="partial"
              sx={{ color: "#FFD700", fontSize: "20px" }}
            />
          );
        }
    
        for (let i = stars.length; i < 5; i++) {
          stars.push(
            <StarOutlinedIcon key={i} sx={{ color: "#3434344A", fontSize: "20px",}} />
          );
        }
    
        return <div>{stars}</div>;
      };

      
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableRow key={row?._id}>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // flexWrap: "wrap",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                fontFamily: "Rosario",

                fontWeight: 500,
                fontSize: "17px",
              }}
            >
              {index+1|| "-"}
            </div>
          </Box>
        </TableCell>

        <TableCell
          sx={{
            fontFamily: "Rosario",

            fontSize: "17px",
          }}
        >

          <div> {row.userDetails ? (
               `Review By:${row?.userDetails.full_name||"-"}` 
       ):(
        <div>
          {`Review By:${row?.full_name}`||"-"}
          </div>
       )}   </div>
        </TableCell>

        <TableCell
          sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
        >
          <div>{row?.eventDetails.name||"-"}</div>
         
        </TableCell>

        <TableCell
          sx={{
            fontFamily: "Rosario",
            fontWeight: 500,
            fontSize: "17px",
          }}
        >
          <div>{row?.review||"-"}</div>
        </TableCell>

        <TableCell
          sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
        >
          <div><StarRating rating={row?.rating||0}/></div>
        </TableCell>

        <TableCell
          sx={{
            fontFamily: "Rosario",
            fontWeight: 500,
            fontSize: "17px",
            // color: "#FFC107",
          }}
        >
          <div>
          {
              row?.created_at ? 
              new Date(row?.created_at || "-").toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
              :"-"
              }
          </div>
        </TableCell>

        <TableCell
          sx={{
            fontFamily: "Rosario",
            fontWeight: 500,
            fontSize: "17px",
            cursor: "pointer",
          }}
        >
          <div>
            <img src={CharMenuIcon} alt="icon" onClick={handleClick} />
          </div>
        </TableCell>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "25px",
              bgcolor: "white",
              boxShadow: "none",
              border: "1px solid #B0B0B0",
              padding: "5px",
              width: "140px",
            },
            "& .MuiMenuItem-root": {
              "&:hover": {
                backgroundColor: "transparent",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "transparent",
              },
              "&:focus": {
                backgroundColor: "transparent",
              },
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={deleteReview}
            sx={{ fontFamily: "Rosario", color: "#EA1D26", fontWeight: "400" }}
          >
            Delete Review
          </MenuItem>
        </Menu>
      </TableRow>
    </>
  );
};

export default ReviewsRow;
