import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import TableCard from '../../common/tableCard/TableCard';
import SearchBar from '../../common/searchbar/SearchBar';
import TableComp from '../../common/tablecomp/TableComp';
import ReviewsRow from '../../common/tablerow/ReviewsRow';
import Card1 from '../../common/card1/Card1';
import { useSelector } from 'react-redux';
import { deleteChefReviewApi, getChefsReviewApi } from '../../api';
import { toast } from 'react-toastify';
import Loader from '../../common/loader/Loader';
import { TableCell, TableRow } from '@mui/material';
import alertDialog from '../../utils/alertDialog';

const ChefReviews = () => {
 
  const token = useSelector((state) => state?.user?.user?.token);
  // const location=useLocation();
  const { state } = useLocation();
  const chefsData = state?.chefsData;

  // console.log("op",chefsData)
  const {id}=useParams()
  
  const [chefsReviewData,setChefsReviewData]=useState([])
  const [ratingData,setRatingData]=useState({
    totalReviews:0,
    avgRating:0
  });
  const [change,setChange]=useState(false)
  const [loading,setLoading]=useState(false);


  const handleChefReview=async()=>{
    try {
      setLoading(true)
      const response=await getChefsReviewApi({token,id})
      // console.log("op",response);
      setChefsReviewData(response?.reviews)
      setRatingData({
        totalReviews:response?.totalReviews,
        avgRating:response?.averageRating
      })
      setLoading(false)
      // setTotalPage(response?.pages);
      // setCurrentPage(response?.page);
    } catch (error) {
      setLoading(false)
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }
  
useEffect(()=>{
  handleChefReview()
},[change])


  const handleDeleteUser=async(id)=>{
    const confirmed = await alertDialog({
      title: 'Confirm Action',
      description: 'Are you sure you want to Delete this Review?'
    });
    if(!confirmed){
      return
    }
    try {
      const response=await deleteChefReviewApi({token,id})
      toast.success(response?.message)
      setChange(!change)
    } catch (error) { 
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }
  


  const style = {
    minHeight: "95px",
    paddingTop: "22px",
    cursor: "default",
  };
  const titles = [
    { title: "Sr No" },
    { title: "Name" },
    { title: "Event Name" },
    { title: "Reviews" },
    { title: "Rating" },
    { title: "Date" },

  ];
 
  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="chefs" to="/dashboard/chefs" className="All-color-breakcurm">
      Chef’s
    </Link>,
    <Link
      key="chefs-details"
      to="/dashboard/chefs/all-chefs"
      className="All-color-breakcurm"
    >
      Chef’s Details
    </Link>,
    <Link
      key="chef-name"
      to={`/dashboard/chefs/all-chefs/chefs-information/${chefsData?.chef_id}`}
     
      className="All-color-breakcurm"
    >
     {chefsData?.full_name||""}
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;Reviews</span>,
  ];
  return (
    <div>
{loading ? (
  <Loader/>
):(
<>
<div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>



      <div className="chef-details-card-container">
        <Card1  value={ratingData?.avgRating ?  parseFloat(ratingData?.avgRating).toFixed(2):0}
          value1={`${ratingData?.totalReviews||0} reviews`}
          text="Total Reviews  >"
          style={style} />

      </div>

    <div className='chef-detail-tble-wrapper'>
    <TableCard>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            padding: "20px",
            fontFamily: "Rosario",
            fontWeight: 600,
            fontSize: "20px",
             gap:'10px'
          }}
        >
          <div>Reviews Details</div>
          <div>
            <SearchBar />
          </div>
        </div>

        <TableComp title={titles} emptyEColumnCount={1}>
          {chefsReviewData?.length > 0 ? (
            chefsReviewData?.map((item,idx) => (
              <ReviewsRow row={item} 
              deleteReview={()=>handleDeleteUser(item?._id)} 
              index={idx}
              key={item?._id}
              />
            ))
          ):(
            <TableRow>
            <TableCell colSpan={titles.length} align="center" sx={{fontFamily:'Rosario',fontWeight:"600"}}>
              No Data Found
            </TableCell>
          </TableRow>
          )}
        </TableComp>
      </TableCard>
    </div>


</>
)}

    </div>
  )
}

export default ChefReviews