import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignUp from './Pages/signup/SignUp';
import EmailVerify from './Pages/forgot/EmailVerify';
import Dashboard from './Pages/dashboard/Dashboard';
import Layout from './common/main/Layout';
import AccManagement from './Pages/accountmanagement/AccManagement';
import AdminManagement from './Pages/adminmanagement/AdminManagement';
import UpdateProfile from './Pages/adminmanagement/UpdateProfile';
import { Setting } from './Pages/setting/Settings';
import PushNotification from './Pages/setting/PushNotificationList';
import CommisionManagement from './Pages/commision/CommisionManagement';
import ChefSection from './Pages/chefs/ChefSection';
import ChefDetails from './Pages/chefs/ChefDetails';
import ChefTicketManagement from './Pages/chefs/ChefTicketManagement';
import ChefContentManagement from './Pages/chefs/ChefContentManagement';
import ChefReport from './Pages/chefs/ChefReport';
import ChefSubscription from './Pages/chefs/ChefSubscription';
import GuestsSection from './Pages/guests/GuestsSection';
import GuestsDetails from './Pages/guests/GuestsDetails';
import GuestsTicketManagement from './Pages/guests/GuestsTicketManagement';
import GuestsContentManagement from './Pages/guests/GuestsContentManagement';
import GuestsSubscription from './Pages/guests/GuestsSubscription';
import ChefPersonalInfo from './Pages/chefs/ChefPersonalInfo';
import EventDetailsbtn from './common/EventDetailsBtn/EventDetailsbtn';
import ChefEarnings from './Pages/chefs/ChefEarnings';
import ChefReviews from './Pages/chefs/ChefReviews';
import ChefEvents from './Pages/chefs/ChefEvents';
import ChefEventsInformation from './Pages/chefs/ChefEventsInformation';
import CommisionEventInfo from './Pages/commision/CommisionEventInfo';
import GuestsPersonalInfo from './Pages/guests/GuestsPersonalInfo';
import GuestsReview from './Pages/guests/GuestsReview';
import GuestsEvents from './Pages/guests/GuestsEvents';
import GuestsEventInfo from './Pages/guests/GuestsEventInfo';
import ChefFAQ from './Pages/chefs/ChefFAQ';
import ChefMission from './Pages/chefs/ChefMission';
import ChefTermCondition from './Pages/chefs/ChefTermCondition';
import ChefPrivacy from './Pages/chefs/ChefPrivacy';
import GuestFAQ from './Pages/guests/GuestFAQ';
import GuestMission from './Pages/guests/GuestMission';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ContentManagement from './Pages/contentmanagement/ContentManagement';
// import FAQ from './Pages/contentmanagement/FAQ';

function App() {
  return (
    <div className="App">
    <ToastContainer />
     <Router>
        <Routes>
          <Route path="/" element={<SignUp/>} />
          <Route path="/forgot" element={<EmailVerify/>} />
        
          <Route path="/dashboard" element={Layout(Dashboard)()}/>
          <Route path="/dashboard/account-management"  element={Layout(AccManagement)()} />
          <Route path="/dashboard/admin-management"  element={Layout(AdminManagement)()} />
          <Route path="/dashboard/admin-management/update-profile"  element={Layout(UpdateProfile)()} />
          <Route path="/dashboard/settings"  element={Layout(Setting)()} />

          {/* <Route path="/dashboard/content-management"  element={Layout(ContentManagement)()} /> */}
          {/* <Route path="/dashboard/content-management/faq"  element={Layout(FAQ)()} /> */}


          <Route path="/dashboard/settings/push-notification"  element={Layout(PushNotification)()} />


          {/* /dashboard/chefs/all-chefs/chefs-information/6735ff1591949a92c20cd444 */}
          {/* /dashboard/chefs/all-chefs/chefs-information/chefs-reviews */}
          {/* chef section */}

              <Route path="/dashboard/chefs"element={Layout(ChefSection)()}/>
              <Route path="/dashboard/chefs/all-chefs"element={Layout(ChefDetails)()}/>
              <Route path="/dashboard/chefs/all-chefs/chefs-information/:id" element={Layout(ChefPersonalInfo)()}/>

              <Route path="/dashboard/chefs/all-chefs/chefs-information/chefs-earnings/:id" element={Layout(ChefEarnings)()}/>
              <Route path="/dashboard/chefs/all-chefs/chefs-information/chefs-reviews/:id" element={Layout(ChefReviews)()}/>
              <Route path="/dashboard/chefs/all-chefs/chefs-information/chefs-events/:id" element={Layout(ChefEvents)()}/>

              <Route path="/dashboard/chefs/all-chefs/chefs-information/chefs-events/events-details/:id" element={Layout(ChefEventsInformation)()}/>



              <Route path="/dashboard/chefs/ticket-management"element={Layout(ChefTicketManagement)()}/>
              <Route path="/dashboard/chefs/content-management"element={Layout(ChefContentManagement)()}/>
              <Route path="/dashboard/chefs/content-management/faq"element={Layout(ChefFAQ)()}/>
              <Route path="/dashboard/chefs/content-management/mission"element={Layout(ChefMission)()}/>
              <Route path="/dashboard/chefs/content-management/privacy-policy"element={Layout(ChefPrivacy)()}/>
              <Route path="/dashboard/chefs/content-management/terms-and-condition"element={Layout(ChefTermCondition)()}/>



              <Route path="/dashboard/chefs/reports"element={Layout(ChefReport)()}/>
              <Route path="/dashboard/chefs/subscription-management"element={Layout(ChefSubscription)()}/>






          {/* guests section */}

          <Route path="/dashboard/guests"element={Layout(GuestsSection)()}/>
          <Route path="/dashboard/guests/all-guests"element={Layout(GuestsDetails)()}/>
          <Route path="/dashboard/guests/all-guests/guests-information/:id"element={Layout(GuestsPersonalInfo)()}/>
          <Route path="/dashboard/guests/all-guests/guests-information/guests-reviews/:id"element={Layout(GuestsReview)()}/>
          <Route path="/dashboard/guests/all-guests/guests-information/guests-events/:id"element={Layout(GuestsEvents)()}/>
          <Route path="/dashboard/guests/all-guests/guests-information/guests-events/events-details/:id"element={Layout(GuestsEventInfo)()}/>



          <Route path="/dashboard/guests/ticket-management"element={Layout(GuestsTicketManagement)()}/>
          <Route path="/dashboard/guests/content-management"element={Layout(GuestsContentManagement)()}/>
          <Route path="/dashboard/guests/content-management/faq"element={Layout(GuestFAQ)()}/>
          <Route path="/dashboard/guests/content-management/mission"element={Layout(GuestMission)()}/>




          <Route path="/dashboard/guests/subscription-management"element={Layout(GuestsSubscription)()}/>




            {/* commison mngemt */}

            <Route path="/dashboard/commision-management"  element={Layout(CommisionManagement)()} />
            <Route path="/dashboard/commision-management/events-details"  element={Layout(EventDetailsbtn)()} />
            <Route path="/dashboard/commision-management/events-details/:id"  element={Layout(CommisionEventInfo)()} />




          </Routes>
      </Router>
</div>
  );
}

export default App;
