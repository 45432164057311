import React from "react";
import { Box, Modal, TextField, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CustomBtn1 from "../../common/customBtn1/CustomBtn1";

const FaqModal = ({ open, onClose, faqData, setFaqData, handleAction }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value.trim() !== "" || value === "") {
        setFaqData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px",
    width: "90%",
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: 24,
    p: 0,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <div className="create-modal-heading">
          <div>{faqData.id ? "Edit FAQ" : "Create New FAQ"}</div>
          <ClearIcon onClick={onClose} style={{ cursor: "pointer" }} />
        </div>

        <div style={{ padding: "20px" }}>
          <Typography className="acc-management-text">FAQ Question</Typography>
          <input type="text"
          className="faq-qestion"
          style={{
            width: "100%",
            border: "none",
            backgroundColor: "#F0F0F0",
            borderRadius: "100px",
            // resize: "none",
            outline: "none",
            textIndent: "20px",
          
            // paddingTop: "20px",
            // minHeight: "100px",
            fontFamily: "Rosario",
            // height:"52px"
          }}
            name="question"
            value={faqData.question}
            onChange={handleInputChange}
            placeholder="Enter here"
            // fullWidth
            // sx={{
            //   fontFamily: "Rosario",
            //   "& .MuiOutlinedInput-root": {
            //     "& fieldset": { border: "none" },
            //     height: "45px",
            //     fontFamily: "Rosario"
            //   },
            // }}
            // InputProps={{
            //   style: {
            //     borderRadius: "28px",
            //     backgroundColor: "#F0F0F0",
            //     fontSize: "14px",
            //     fontFamily: "Rosario !important",
            //     opacity:0.6
            //   },
            // }}
          />
        </div>

        <div style={{ padding: "20px" }}>
          <Typography className="acc-management-text">FAQ Answer</Typography>
          <textarea
            
            name="answer"
            value={faqData.answer}
            onChange={handleInputChange}
            placeholder="Enter here"
            rows={6}
            style={{
                width: "100%",
                border: "none",
                backgroundColor: "#F0F0F0",
                borderRadius: "25px",
                resize: "none",
                outline: "none",
                textIndent: "20px",
                paddingTop: "20px",
                minHeight: "100px",
                fontFamily: "Rosario",
                // opacity:0.6
                
              }}
          />
        </div>

        <div style={{ margin: "20px" }}>
          <CustomBtn1
            text={faqData.id ? "Update FAQ" : "Add New FAQ "}
            onClick={handleAction}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default FaqModal;
