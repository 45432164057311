import React from "react";
import { TableRow, TableCell, Box, Avatar } from "@mui/material";
import userimg from "../../common/assest/usrimg.png";
import CharMenuIcon from "../assest/charmenuicon.svg";
import { useNavigate } from "react-router-dom";
import EditIcon from "../assest/editicon.svg";
import DeletIcon from "../assest/deleteicon.svg";

const NotificationRow = ({row,deleteNotification,edit}) => {
  const navigate = useNavigate();

 

  return (

        <TableRow>
          <TableCell>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <div
                style={{
                  fontFamily: "Rosario",

                  fontWeight: 500,
                  fontSize: "17px",
                }}
              >
                {row?.title||"N/A"}
              </div>
            </Box>
          </TableCell>

          <TableCell
            sx={{
              fontFamily: "Rosario",
              fontSize: "16px",
            }}
          >
            <div>{row?.category||"N/A"}</div>
          </TableCell>

          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "16px" }}
          >
           <div>{row?.description||"N/A"}</div>
          </TableCell>

          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "16px" }}
          >
            <div>{
              row?.publishing_date ? 
              new Date(row?.publishing_date || "-").toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
              :"-"
              }</div>
          </TableCell>

          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "16px" }}
          >
            <div>{row?.send_to||"N/A"}</div>
          </TableCell>

          <TableCell
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              cursor: "pointer",
              flexWrap:'wrap',
              gap:'10px'
            }}
          >
            <div
            onClick={edit}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#595555",
                height: "43px",
                width: "43px",
                alignItems: "center",
                borderRadius: "10px",
              }}
            >
              <img src={EditIcon} />
            </div>
            <div onClick={deleteNotification}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#EA1D26",
                height: "43px",
                width: "43px",
                alignItems: "center",
                borderRadius: "10px",
              }}
            >
              <img src={DeletIcon} />
            </div>
          </TableCell>
        </TableRow>
      

  );
};

export default NotificationRow;
