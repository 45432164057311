import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./AdminModal.css";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, InputAdornment, TextField } from "@mui/material";
import CustomBtn1 from "../../common/customBtn1/CustomBtn1";
import EyeClose from "../../common/assest/ion_eye-off.svg";
import EyeOpen from "../../common/assest/opnEye.svg";
import { toast } from "react-toastify";


export default function AdminModal({ open, handleClose,onSubmit }) {

  const [admin, setAdmin] = useState({ fullName: "", email: "", password: "" });
  const [showPass,setShowPass]=useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false);



  useEffect(() => {
    if (!open || formSubmitted) {
      setAdmin({ fullName: "", email: "", password: "" });  
      setFormSubmitted(false); 
    }
  }, [open, formSubmitted]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.trim() !== "" || value === "") {
        setAdmin((prev) => ({ ...prev, [name]: value }));
    }
};
const handlePassowrdToggle = () => {
    setShowPass(!showPass);
  };

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  
  const handleFormSubmit = () => {
    // Validate each field and show specific toast warnings
    if (admin.fullName === "") {
      toast.warning("Full Name is required.");
    }
     else if (admin.email === "") {
      toast.warning("Email ID is required.");
    } 
    else if (!validateEmail(admin.email)) {
      toast.error("Please provide a valid email address.");
    }
    else if (admin.password === "" ) {
      toast.warning("Password is required.");
    }
    else if (admin.password.length <8) {
      toast.warning("Password should be 8 characters long");
    }
    else if (!validateEmail(admin.email)) {
      toast.error("Please provide a valid email address.");
    }
    else {
      onSubmit(admin);  
      setFormSubmitted(true);
    }
  };
  

  const modalWidth = window.innerWidth < 500 ? "90%" : "90%";
  const maxWidth = window.innerWidth < 576 ? "400px" : "590px";
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: maxWidth,
    width: modalWidth,
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: 24,
    p: 0,
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className="create-modal-heading">
            <div>Create New Admin</div>
            <ClearIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>

          <Grid container spacing={2} sx={{ padding: "20px" }}>
            {/* First Name Row */}
            <Grid item xs={12} sm={12}>
              <Typography className="acc-management-text">Full Name</Typography>
              <TextField
                type="text"
                placeholder="Enter your Full Name"
                name="fullName"
                value={admin.fullName}
                onChange={handleChange}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    height: "45px",
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "28px",
                    backgroundColor: "#F0F0F0",
                    fontSize: "14px",
                    fontFamily: "Rosario",
                  },
                }}
              />
            </Grid>

            {/* Lasgt Name Row */}
            {/* <Grid item xs={12} sm={6}>
                            <Typography className="acc-management-text">Last Name</Typography>
                            <TextField
                                type="text"
                                placeholder="Enter your Last Name"
                                name="email"
                                fullWidth
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            border: "none",
                                        },
                                        height: '45px',
                                    },
                                }}
                                InputProps={{
                                    style: {
                                        borderRadius: "28px",
                                        backgroundColor: "#F0F0F0",
                                        fontSize: "14px",
                                        fontFamily: "Rosario",
                                    },
                                }}
                            />
                        </Grid> */}

            {/* Email Row */}
            <Grid item xs={12} sm={6}>
              <Typography className="acc-management-text">Email ID</Typography>
              <TextField
                type="text"
                placeholder="Enter your email"
                name="email"
                value={admin.email}
                onChange={handleChange}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    height: "45px",
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "28px",
                    backgroundColor: "#F0F0F0",
                    fontSize: "14px",
                    fontFamily: "Rosario",
                  },
                }}
              />
            </Grid>

            {/* Password*/}
            <Grid item xs={12} sm={6}>
              <Typography className="acc-management-text">Password</Typography>
              <TextField
                type={showPass?"text":"password"}
                placeholder="Enter  Password"
                name="password"
                value={admin.password}
                onChange={handleChange}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                    height: "45px",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img

                        src={showPass? EyeOpen : EyeClose}
                        alt="toggle-password-visibility"
                        className="eyeicon"
                        onClick={handlePassowrdToggle}
                        style={{ width: "20px", cursor: "pointer" }}
                      />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "28px",
                    backgroundColor: "#F0F0F0",
                    fontSize: "14px",
                    fontFamily: "Rosario",
                  },
                }}
              />
            </Grid>

            {/*Create Admin */}
            <Grid item xs={12} mb={2} mt={2}>
              <CustomBtn1 text="Create" fullWidth onClick={handleFormSubmit} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
