import React, { useEffect, useState } from 'react';
import { IconButton, Badge, Button, Menu, MenuItem, MenuList } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NavBarImg from '../assest/navbarImg.png';
import ProfileIcon from '../assest/logoutIcon.svg';
import './Main.css';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/userSlice';
import { toast } from 'react-toastify';
import alertDialog from '../../utils/alertDialog';

const Navbar = ({ toggleSidebar }) => {
  const userData = useSelector((state) => state?.user?.user);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [user, setUser] = useState();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNotificationAnchorEl(null);
  };

  const toggleNotificationDropdown = () => {
    setShowNotification(!showNotification);
  };

  const closeNotification = () => {
    setShowNotification(false);
  };

  const logOut =async () => {
    const confirmed = await alertDialog({
      title: 'Confirm Action',
      description: 'Are you sure you want to logout?'
    });
    if(!confirmed){
      return
    } else {
      dispatch(logout());
      toast.success('Admin Logout');
    }
  };

  return (
    <nav>
      <img src={NavBarImg} className="c" alt="top banner" />
      <div className="header">
        <div
          style={{
            width: '55%',
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={toggleSidebar} className="hamburger-menu">
            <MenuOutlinedIcon sx={{ color: '#FFF', fontSize: '30px' }} />
          </IconButton>
          <div className="welcome-container">
            <div className="welcome-text-dashboard">
              Welcome, {userData?.full_name || 'Admin'}
            </div>
          </div>
        </div>
        <div className="icons" style={{ marginRight: '20px' }}>
          <IconButton onClick={handleNotificationClick}>
            <div
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: '#EA1D26',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <Badge
                badgeContent=" "
                variant="dot"
                overlap="circular"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiBadge-dot': {
                    backgroundColor: '#FFE20A',
                  },
                }}
              >
                <NotificationsIcon style={{ color: 'white', fontSize: '25px' }} />
              </Badge>
            </div>
          </IconButton>

          {/* Notification Dropdown */}
          <Menu
            anchorEl={notificationAnchorEl}
            open={Boolean(notificationAnchorEl)}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'notifications-button',
            }}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: '16px',
                background: '#FFF',
                boxShadow: '2px 4px 16px 0px rgba(0, 0, 0, 0.20)',
                width: {xs:238,sm:320},
                minHeight: '200px',
                display: 'flex',
                flexDirection: 'column',
                padding: '15px',
                color: '#9A031E',
                fontWeight: '600',
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              <div>Notifications</div>
              <div
                style={{
                  color: 'black',
                  backgroundColor: '#EBEBEB',
                  height: '22px',
                  width: '25px',
                  borderRadius: '50%',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
                onClick={handleClose}
              >
                <CloseIcon sx={{ fontSize: '15px', textAlign: 'center' }} />
              </div>
            </div>
            {/* {notificaion} */}
          </Menu>

          <IconButton>
            <img
              src={ProfileIcon}
              style={{ height: '40px' }}
              alt="Profile"
              onClick={handleClick}
            />
          </IconButton>

          {/* Profile Menu */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: '16px 0px 16px 16px',
                background: '#FFF',
                boxShadow: '2px 4px 16px 0px rgba(0, 0, 0, 0.20)',
                width: '100px',
                height: '38px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#9A031E',
                fontWeight: '600',
                cursor: 'pointer',
              },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div onClick={logOut} style={{ fontFamily: 'Rosario' }}>
              Logout
            </div>
          </Menu>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
