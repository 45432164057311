import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CustomBtn1 from "../../common/customBtn1/CustomBtn1";
import ClearIcon from "@mui/icons-material/Clear";
// import './ChefFAQ.css';
import EditIcon from '../../common/assest/editfaq.svg';
import DeleteIcon from '../../common/assest/deletefaq.svg';
import FaqModal from "../../common/modal/FaqModal";
import { addGuestFaqApi, deleteGuestFaqApi, editGuestFaqApi, getAllGuestFaqApi } from "../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../common/loader/Loader";
import alertDialog from "../../utils/alertDialog";

const GuestFAQ = () => {
  const token = useSelector((state) => state?.user?.user?.token);

  const [open, setOpen] = useState(false);
  const [faqData, setFaqData] = useState({ question: "", answer: "" });
  const [allFaq, setAllFaq] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchFaq = async () => {
    try {
      setLoading(true);
      const response = await getAllGuestFaqApi({ token });
      setAllFaq(response || []);
    } catch (error) {
      console.error("Error fetching FAQs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFaq();
  }, []);

  const openAddModal = () => {
    setFaqData({ question: "", answer: "" });
    setOpen(true);
  };

  const openEditModal = (faq) => {
    setFaqData({ id: faq._id, question: faq.title, answer: faq.content });
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setFaqData({ question: "", answer: "" });
  };

  const handleAddFaq = async () => {
    try {
      const response = await addGuestFaqApi({ token, faqData });
      const successMsg = response?.message || "FAQ Created";
      toast.success(successMsg);
      fetchFaq();
      closeModal();
    } catch (error) {
        console.error("Error deleting FAQ:", error);
        const errorMessage = error?.data?.errors;
        if (Array.isArray(errorMessage)) {
          errorMessage.forEach((msg) => toast.error(msg));
        } else if (errorMessage) {
          toast.error(errorMessage);
        } else {
          toast.error("Unable to Edit  FAQ");
        }
      }
    };


  const handleEditFaqSubmit = async () => {
    const confirmed = await alertDialog({
      title: 'Confirm Action',
      description: 'Are you sure you want to Edit this FAQ?'
    });
    if(!confirmed){
      return
    }
    try {
      const response = await editGuestFaqApi({ token, faqData });
      const successMsg = response?.message || "FAQ Updated";
      toast.success(successMsg);
      fetchFaq();
      closeModal();
    } catch (error) {
        console.error("Error deleting FAQ:", error);
        const errorMessage = error?.data?.errors;
        if (Array.isArray(errorMessage)) {
          errorMessage.forEach((msg) => toast.error(msg));
        } else if (errorMessage) {
          toast.error(errorMessage);
        } else {
          toast.error("Unable to Edit  FAQ");
        }
      }
    };


  const handleDeleteFaq = async (id) => {
    const confirmed = await alertDialog({
      title: 'Confirm Action',
      description: 'Are you sure you want to Delete this FAQ?'
    });
    if(!confirmed){
      return
    }
    try {
      const response = await deleteGuestFaqApi({ token, id });
      const successMsg = response?.message || "FAQ Deleted";
      toast.success(successMsg);
      fetchFaq();
    } catch (error) {
        console.error("Error deleting FAQ:", error);
        const errorMessage = error?.data?.errors;
        if (Array.isArray(errorMessage)) {
          errorMessage.forEach((msg) => toast.error(msg));
        } else if (errorMessage) {
          toast.error(errorMessage);
        } else {
          toast.error("Unable to delete FAQ");
        }
      }
    };

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="chef" to="/dashboard/guests" className="All-color-breakcurm">
      Guest's
    </Link>,
    <Link key="content-management" to="/dashboard/guests/content-management" className="All-color-breakcurm">
      Content
    </Link>,
    <span key="faq" style={{ fontWeight: "bolder" }}>&nbsp;FAQ</span>,
  ];

  return (
    <>
      {loading ? (
        <Loader/>
      ) : (
        <div>
          <div className="bread-crum">
            {breadcrumbItems.map((item, index) => (
              <div className="all-breakcrum" key={index}>
                {item}
                {index < breadcrumbItems.length - 1 && " > "}
              </div>
            ))}
          </div>

          <div className="create-btn-container">
            <Button
              onClick={openAddModal}
              variant="contained"
              className="create-admin-btn"
              startIcon={<AddCircleOutlineOutlinedIcon className="create-admin-icon" />}
            >
              Create New FAQ
            </Button>
          </div>

          {allFaq.length > 0 ? (
            allFaq.map((faq, index) => (
              <div className="FAQ-whole-container" key={index}>
                <div className="faq-btn-container">
                  <div className="edit-btn" onClick={() => openEditModal(faq)}>
                    <img src={EditIcon} alt="Edit" className="edit-icon" />
                    Edit
                  </div>
                  <div
                    className="delete-btn"
                    onClick={() => handleDeleteFaq(faq?._id)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={DeleteIcon} alt="Delete" className="delete-icon" />
                    Delete
                  </div>
                </div>
                <div className="faqtitle-description-container">
                  <div className="faq-title-text">{faq.title || "N/A"}</div>
                  <div className="faq-desc-text">{faq.content || "N/A"}</div>
                </div>
              </div>
            ))
          ) : (
            <div style={{ textAlign: "center" }}>No FAQ Found</div>
          )}

          <FaqModal
            open={open}
            onClose={closeModal}
            faqData={faqData}
            setFaqData={setFaqData}
            handleAction={faqData?.id ? handleEditFaqSubmit : handleAddFaq} 
          />
        </div>
      )}
    </>
  );
};

export default GuestFAQ;
