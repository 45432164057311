import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import CalenderLogo from "../../common/assest/datesvg.svg";
import EventDetailsbtn from "../../common/EventDetailsBtn/EventDetailsbtn";
import { useSelector } from "react-redux";

const GuestsEventInfo = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const location = useLocation();
  // const rowData = location.state;
  const { rowData, row, username, userId} = location.state || {};
  // console.log("guest1",rowData)
  // console.log("guest2",row)
  // console.log("Username:", username);
  // console.log("UserId:", userId);
  const {id}=useParams()

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/chefs" className="All-color-breakcurm">
       Guest’s
    </Link>,
    <Link
      key="home"
      to="/dashboard/guests/all-guests"
      className="All-color-breakcurm"
    >
       Guest’s Details
    </Link>,
    <Link
      key="home"
      to={`/dashboard/guests/all-guests/guests-information/${userId}`}
      className="All-color-breakcurm"
    >
      {username? username:"N/A"}
    </Link>,
    <Link
      key="home"
      to={`/dashboard/guests/all-guests/guests-information/guests-events/${userId}`}
      className="All-color-breakcurm"
    >
       Events
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;{rowData?.name}</span>,
  ];
  return (
    <div>
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <div className="date-event-details" style={{display:'flex',alignItems:'center',justifyContent:'start',gap:"20px"}}>
        <div>
          <img src={CalenderLogo} alt="calender-logo" />
        </div>
        Event Details: {
              rowData?.event_date ? 
              new Date(rowData?.event_date || "-").toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
              :"-"
              } | {rowData?.event_start_time||""} to {rowData?.event_end_time||"-"}
      </div>

      <div className="chef-events-information-details">
        <EventDetailsbtn data={rowData}/>
      </div>
    </div>
  );
};

export default GuestsEventInfo;
