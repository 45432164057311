import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import SendIcon from "@mui/icons-material/Send";
import { chefChatDataApi, chefChatMessageApi } from "../../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const TicketDrawer = ({ isOpen, ticket, onClose }) => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const token = useSelector((state) => state?.user?.user?.token);
  const adminId=useSelector((state)=> state?.user?.user?._id);
  const truncateText = (text, maxLength) =>
    text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  // fetching all msgs
  const handleMessageData = async () => {
    try {
      const response = await chefChatDataApi({ token, ticket: ticket?._id });
      setMessages(response?.data || []);
      console.log("af",response)
    } catch (error) {
      const errorMessage = error?.response?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };



    // sending  msgs
 const handleSendMessage = async (ticket) => {
  
  try {
    // Fetching the `to` and `from` IDs dynamically

    // const toUserId = messages[0]?.to_user[0]?._id;
    const fromUserId = messages[0]?.from_user[0]?._id; 

    // if (!toUserId || !fromUserId) {
    //   toast.error("Unable to determine recipient or sender details.");
    //   return;
    // }

    const messagePayload = {
      ticket: ticket?._id,
      to: adminId,
      from: fromUserId,
      message: inputValue,
    };

    const res = await chefChatMessageApi({ token, messagePayload });
    setInputValue("");
    handleMessageData();
  } catch (error) {
    console.error("Error sending message:", error);
    const errorMessage = error?.response?.data?.errors;
    if (Array.isArray(errorMessage)) {
      errorMessage.forEach((msg) => toast.error(msg));
    } else if (errorMessage) {
      toast.error(errorMessage);
    }
  }
};

  useEffect(() => {
    if (isOpen && ticket?._id) {
      handleMessageData();
    }
  }, [ticket, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setMessages([]);
      setInputValue("");
    }
  }, [isOpen]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "25px 0px 0px 25px", 
        },
      }}
    >
      {ticket && (
        <div
          className="drawer-content"
          style={{ width: "283px", padding: "20px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div>
              <div
                style={{
                  color: "#EA1D26",
                  fontFamily: "Rosario",
                  fontSize: "24px",
                }}
              >
                Reply to 
              </div>
              <div style={{ color: "#707070", fontFamily: "Rosario" }}>
              {messages[0]?.to_user[0]?.user_details[0]?.email || "No email available"}
              </div>
            </div>
            <button
              className="close-drawer"
              onClick={onClose}
              style={{
                border: "none",
                background: "none",
                fontSize: "1.5rem",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </div>

          {/* Title and Description */}
          <div className="title" style={{ marginTop: "20px" }}>
            {truncateText(ticket?.title, 40) || "No title available"}
          </div>
          <p>
            {truncateText(ticket?.description, 80) || "No description available"}
          </p>

          {/* Chat Section */}
          <div className="chat-section-container" style={{ marginTop: "20px" }}>
  {messages.map((msg) => (
    <div
      key={msg._id}
      style={{
        display: "flex",
        // backgroundColor: msg.from_user[0]?._id === adminId ? "#EA1D26" : "#F6F6F6",
        justifyContent: msg.from_user[0]?._id === adminId ? "flex-end" : "flex-start",
        margin: "10px 0",
        textAlign:'center'
      }}
    >
      <div
        style={{
          backgroundColor: msg.from_user[0]?._id === adminId ? "#EA1D26" : "#F6F6F6",
          color: msg.from_user[0]?._id === adminId ? "#FFF" : "#000",
          padding: "5px",
          borderRadius: "8px",
          maxWidth: "100%",
          wordBreak: "break-word",
          
        }}
      >
        {msg.message}
      </div>
    </div>
  ))}
</div>


          {/* Input Section */}
          {ticket?.status === "open" && (
            <div
              className="send-chat-reply-btn-container"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <input
                type="text"
                className="chat-input"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Enter your message"
              />
              <div
                className="send-btn-icon"
                onClick={()=>handleSendMessage(ticket)}
                style={{ cursor: "pointer" }}
              >
                <SendIcon />
              </div>
            </div>
          )}
        </div>
      )}
    </Drawer>
  );
};

export default TicketDrawer;
