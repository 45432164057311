import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Card1 from "../../common/card1/Card1";
import "./ChefDetails.css";
import CarbonAvtar from "../../common/assest/carbonavatar.svg";
import TableCard from "../../common/tableCard/TableCard";
import ChefRow from "../../common/tablerow/ChefRow";
import TableComp from "../../common/tablecomp/TableComp";
import SearchBar from "../../common/searchbar/SearchBar";
import userimg from "../../common/assest/usrimg.png";
import PdfIcon from "../../common/assest/pdf-icon.svg";
import CustomModal from "../../common/modal/CustomModal";
import ClearIcon from "@mui/icons-material/Clear";
import SearchWithFilter from "../../common/searchbar/SearchWithFilter";
import { chefDetailsApi, deletChefApi, getChefsActiveCountApi, getChefsCountApi, getChefsCountInMonthApi, suspendChefApi } from "../../api";
import { useSelector } from "react-redux";
import Loader from "../../common/loader/Loader";
import { toast } from "react-toastify";
import { Avatar, TableCell, TableRow } from "@mui/material";
import Paginations from "../../common/pagintaion/Pagination";
import alertDialog from "../../utils/alertDialog";
import formatDate from "../../utils/birthDateConversion";


const ChefDetails = () => {

  const token = useSelector((state) => state?.user?.user?.token);
  const [loading,setLoading]=useState(false);
  const [counts,setCounts]=useState({
    totalChef:0,
    activeChef:0,
    totalChefInMonth:0
  });
  const [allChefsData,setAllChefsData]=useState([])
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [searchQuery,setSearchQuery]=useState("");
  const [myTimout, setMyTimeOut] = useState();
  const [modalData,setModalData]=useState({})
  const [change,setChange]=useState(false)
  // const [ids,setIds]=useState('')
  const [resetting, setResetting] = useState(false); 
 



  const handleChefDetails=async()=>{
    try {
      if (!resetting) setLoading(true);
      const response=await chefDetailsApi({token,currentPage,searchQuery})
      // console.log("op",response);
      setCounts({
        totalChef:response?.total_count_chef||0,
        activeChef:response?.active_chef_count||0,
        totalChefInMonth:response?.total_count_chef_current_month||0
      })
      setAllChefsData(response?.data)
      setLoading(false)
      setCurrentPage(response?.page);
      setTotalPage(response?.pages);
    } catch (error) {
      setLoading(false)
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }
  
useEffect(()=>{
handleChefDetails()
},[currentPage,change])


const resetPage = () => {
  setResetting(true); 
  setCurrentPage(1);
};


const handleDeleteUser=async(id)=>{
  const confirmed = await alertDialog({
    title: 'Confirm Action',
    description: 'Are you sure you want to Delete this User?'
  });
  if(!confirmed){
    return
  }
  try {
    const response=await deletChefApi({token,id})
    toast.success(response?.message)
    setChange(!change)
  } catch (error) { 
    const errorMessage = error?.data?.errors;
    if (Array.isArray(errorMessage)) {
      errorMessage.forEach((msg) => toast.error(msg));
    } else if (errorMessage) {
      toast.error(errorMessage);
    }
  }
}

const handleSuspendUser=async(id,item)=>{
  const msg=item?.is_banned ? "Resume" :"Suspend"
  const confirmed = await alertDialog({
    title: 'Confirm Action',
    description: `Are you sure you want to ${msg} this User?`
  });
  if(!confirmed){
    return
  }
  try {
    const response=await suspendChefApi({token,id})
    toast.success(response?.message)
    setChange(!change)
  } catch (error) {
    const errorMessage = error?.data?.errors;
    if (Array.isArray(errorMessage)) {
      errorMessage.forEach((msg) => toast.error(msg));
    } else if (errorMessage) {
      toast.error(errorMessage);
    }
  }
}




  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openModal = (item) => {
    setModalData(item)
    setOpen(true);
  };

  // console.log("first",modalData)
  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/chefs" className="All-color-breakcurm">
      Chef’s
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;Chef’s Details</span>,
  ];

  const style = {
    minHeight: "95px",
    paddingTop: "22px",
    cursor: "default",
  };

  const titles = [
    { title: "Chef Name" },
    { title: "Email" },
    { title: "Experience" },
    { title: "No of Events" },
    { title: "Ratings" },
    { title: "Earnings" },
    { title: "Joined Date" },
    { title: "Subscription " },
    { title: "Account Status " },
  ];
 

  // useEffect(() => {
  //     clearTimeout(myTimout);
  //     const timeout = setTimeout(() => handleChefDetails(), 500);
  //     setMyTimeOut(timeout);
      
  //     return () => clearTimeout(timeout);
  // }, [searchQuery]);

  // useEffect(() => {
  
  //     clearTimeout(myTimout);
  //     setMyTimeOut(setTimeout(handleChefDetails, 500));
  
  // }, [searchQuery]);

  useEffect(() => {
    if (searchQuery.trim() == "") {
      handleChefDetails();
    } else {
      clearTimeout(myTimout);
      setMyTimeOut(setTimeout(handleChefDetails, 500));
      // Cleanup on unmount or re-render
      // return () => clearTimeout(timeout);
    }
  }, [searchQuery]);
  

  const handlePageChange = (page) => {
    setCurrentPage(page); 
  };

  
  return (
    <>
    
    
    {loading ?(
      
      <Loader/>
    ):(
        
    <div>
     
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <div className="chef-details-card-container">
        <Card1 value={counts?.totalChef||0} text="Total Chefs" style={style} />
        <Card1 value={counts?.totalChefInMonth||0} text="Total Users This Month" style={style} />
        <Card1
          value={counts?.activeChef||0}
          text="Active Users Currently on App"
          style={style}
        />
      </div>

     <div className="chef-detail-tble-wrapper">
     <TableCard>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            padding: "20px",
            fontFamily: "Rosario",
            fontWeight: 600,
            fontSize: "20px",
            gap:'10px'
          }}
        >
          <div>Chef’s Details</div>
          <div>
          <SearchWithFilter searchQuery={searchQuery} setSearchQuery={setSearchQuery} resetPage={resetPage}/>
          </div>
        </div>

        <TableComp title={titles} emptyEColumnCount={2}  currentPage={currentPage}
              totalPage={totalPage}
              onPageChange={handlePageChange}
              loading={loading}
              >
          {allChefsData.length >0 ? (
                allChefsData.map((item) => (
                  <ChefRow 
                  // handleViewMore={() => openModal(item)}
                   row={item} 
                  deleteUser={()=>handleDeleteUser(item?._id)} 
                  suspendUser={()=>handleSuspendUser(item?._id,item)}
                  key={item?._id}
                  />
                ))
          ):(
                <TableRow>
                  <TableCell colSpan={titles.length} align="center" sx={{fontFamily:'Rosario',fontWeight:"600"}}>
                    No Data Found
                  </TableCell>
                </TableRow>
          )}
         
          
        </TableComp>

       
      </TableCard>
     </div>

      {/* <CustomModal open={open} handleClose={handleClose}>
        <div
          onClick={handleClose}
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            color: "#6E6E6E",
            cursor: "pointer",
          }}
        >
          <ClearIcon />
        </div>

        <div className="img-mail-name-container">
            <div><img src={modalData?.profile_picture||CarbonAvtar}/></div>
            <div className="name-email-container">
                <div  style={{fontFamily:'Rosario',fontWeight:'700'}}>{modalData?.full_name||"N/A"}</div>
                <div style={{color:'#868686',fontFamily:'Rosario'}}>{modalData?.email||"N/A"}</div>
            </div>
          </div>
          <div className="modal-txt">Address:
          {Array.isArray(modalData?.full_address) && modalData?.full_address.length > 0 ? (
            <span>
              {" "}{modalData.full_address[0]?.street_address},{modalData.full_address[0]?.city},{modalData.full_address[0]?.state}, {modalData.full_address[0]?.zipcode}
            </span>
          ) : (
            "N/A"
          )}
        </div>


        <div className="modal-txt">
            Birthdate:
            <span>
               {modalData?.date_of_birth
                ? formatDate(modalData.date_of_birth)
                : "N/A"}
            </span>
          </div>


          <div className="modal-txt">
            Specialty:
            <span>
              {modalData?.specialities && modalData.specialities.length > 0
                ? modalData.specialities.map((language, index) => (
                    <span key={index}>
                      {language}
                      {index < modalData.specialities.length - 1 ? ', ' : ''}
                    </span>
                  ))
                : "N/A"}
            </span>
          </div>

        <div className="modal-txt">Cooking Style:<span>{modalData?.cooking_style||"N/A"} </span> </div>
        <div className="modal-txt">Language:
          <span>
          {modalData?.languages_spoken && modalData.languages_spoken.length > 0
                ? modalData.languages_spoken.map((language, index) => (
                    <span key={index}>
                      {language}
                      {index < modalData.languages_spoken.length - 1 ? ', ' : ''}
                    </span>
                  ))
                : "N/A"}
            </span> 
          </div>
        <div className="modal-txt">Food Handler Section:<span>{modalData?.foodhandler||"N/A"}</span> </div>
        <div className="modal-txt">Bank Account Details: 
          <p>Bank Name:<span> {modalData?.bank||"N/A"}</span> </p> 
          <p>Account Holders Name: <span> {modalData?.bank||"N/A"}</span> </p> 
          <p>Bank Code:<span> {modalData?.bank||"N/A"}</span> </p> 
          <p>Account Number:<span> {modalData?.bank||"N/A"}</span> </p> 
      </div>
      <div className="modal-txt">
  Portfolio:
  {modalData?.portfolio ? ( 
    <div className="whole-pdf">
      <a
        href={modalData.portfolio}
        target="_blank" 
        rel="noopener noreferrer" 
        className="portfoliodesign-container"
        style={{ textDecoration: 'none', color: 'inherit' }} 
      >
        <div className="pdf-logo-container">
          <img alt="pdf-img-logo" src={PdfIcon} />
        </div>
        <div>
          <div style={{color:'#000',fontSize:'14px',marginTop:'10px'}}>{modalData?.portfolio_name || 'pdf-document'}</div>
          
        </div>
      </a>
    </div>
  ) : (
    <span style={{color:'#000'}}>
    {" "} No Portfolio Available
     </span>
  )}
</div>

      </CustomModal> */}

      <div className="txt-sample">
        Copyright © 2024 Chef’s Table All rights reserved.
      </div>
    </div>
     )}
    </>
  );
};

export default ChefDetails;
