import React, { useEffect, useState } from 'react'
import Card1 from '../../common/card1/Card1'
import Guest from '../../common/assest/guest.png'
import Chef from '../../common/assest/Chef.png'
import Commision from '../../common/assest/commision.png'
import './Dashboard.css'
import { useNavigate } from 'react-router-dom'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from 'recharts';
import { useSelector } from 'react-redux'
import { getGraphData } from '../../api'

const Dashboard = () => {

  const token = useSelector((state) => state?.user?.user?.token);
  const navigate = useNavigate();
  const [data,setData]=useState([]);
  const [chefGusetCount,setChefGuestCount]=useState({
    chefCount:0,
    GuestCount:0
  })
  const [curentDte,setCurrentDte]=useState(new Date().getFullYear())
  // const dummyData = [
  //   { month: 'January', total_chefs: 30, total_guests: 15 },
  //   { month: 'February', total_chefs: 5, total_guests: 18 },
  //   { month: 'March', total_chefs: 4, total_guests: 20 },
  //   { month: 'April', total_chefs: 6, total_guests: 22 },
  //   { month: 'May', total_chefs: 2, total_guests: 10 },
  //   { month: 'June', total_chefs: 7, total_guests: 25 },
  //   { month: 'July', total_chefs: 8, total_guests: 30 },
  //   { month: 'August', total_chefs: 3, total_guests: 12 },
  //   { month: 'September', total_chefs: 4, total_guests: 18 },
  //   { month: 'October', total_chefs: 5, total_guests: 20 },
  //   { month: 'November', total_chefs: 6, total_guests: 15 },
  //   { month: 'December', total_chefs: 7, total_guests: 28 }
  // ];

  const getBarData=async()=>{
      try {
        const response= await getGraphData({token});
          // console.log("first",response)
        setData(response?.monthly_stats)
        setChefGuestCount({
          chefCount:response?.totalOverallChefs,
          GuestCount:response?.totalOverallGuests
        })
        
      } catch (error) {
        console.log("error",error)
      }
  }

  //  with slices 
  // const getBarData = async () => {
  //   try {
  //     const response = await getGraphData({ token });

  //     const formattedData = response.map(item => ({
  //       ...item,
  //       month: item.month.slice(0, 3)
  //     }));

  //     setData(formattedData);
  //   } catch (error) {
  //     console.log("error", error)
  //   }
  // }


  useEffect(()=>{
    getBarData();
  },[])

  const handleChefs = () => {
    navigate('/dashboard/chefs')
  }
  const handleGuests = () => {
    navigate('/dashboard/guests')
  }
  const handleCommision = () => {
    navigate('/dashboard/commision-management')
  }
  
  // const CustomTooltip = ({ payload, label, active }) => {
  //   if (active && payload && payload.length) {
  //     const chefs = payload.find(p => p.dataKey === 'total_chefs');
  //     const guests = payload.find(p => p.dataKey === 'total_guests');


  //     const monthLabel = label.slice(0, 3);

  //     return (
  //       <div style={{
  //         background: '#fff', 
  //         borderRadius: '8px', 
  //         padding: '10px', 
  //         boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  //         width: '150px'
  //       }}>
  //         <p style={{ margin: '5px 0', fontWeight: 'bold', color: '#333' }}>{`Month: ${monthLabel}`}</p>
  //         <p style={{ margin: '5px 0', color: '#555' }}>{`Chefs: ${chefs ? chefs.value : 0}`}</p>
  //         <p style={{ margin: '5px 0', color: '#555' }}>{`Guests: ${guests ? guests.value : 0}`}</p>
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  return (
    <div>

      <div className='dashboard-card'>
        <Card1 text="Chefs" value={chefGusetCount?.chefCount||0} img={Chef} onClick={handleChefs} />
        <Card1 text="Guests" value={chefGusetCount?.GuestCount||0}img={Guest} onClick={handleGuests} />
        <Card1 text="Commission Management" value="300" img={Commision} onClick={handleCommision} />
      </div>

      <div className='graph-container' >
        <div className='report-txt-grph' style={{paddingLeft:'10px',marginTop:"10px"}}>Reports & Analytics</div>
        <div className='no-of-enroll' style={{paddingLeft:'10px'}}>{`No of chefs and guests enrolled in ${curentDte}`}</div>
        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data}>
              <Legend  className="custom-legend" layout="horizontal" align="right" radius={25}   verticalAlign="top" wrapperStyle={{ marginTop: -35,marginLeft:50 }} />
              <Tooltip formatter={(value) => `${value}`} cursor={{ fill: 'none' }} />
              {/* <Tooltip content={<CustomTooltip />} cursor={{ fill: 'none' }} /> */}
              <Bar dataKey="total_chefs" fill="#F33" radius={[25, 25, 25, 25]} barSize={25} name="Chefs" />
              <Bar dataKey="total_guests" fill="#B6B6B6" barSize={25} radius={[25, 25, 25, 25]} name="Guests" />
              <XAxis dataKey="month" />
            </BarChart>
          </ResponsiveContainer>
        </div>
     

      <div className='txt-sample'>
        Copyright © 2024 Chef’s Table All rights reserved.
      </div>

    </div>
  )
}

export default Dashboard