import React, { useEffect, useState } from 'react'
import './PushNotification.css';
import { Link } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Button, TableCell, TableRow } from '@mui/material';
import CreateNotification from './CreateNotification';
import TableCard from '../../common/tableCard/TableCard';
import SearchBar from '../../common/searchbar/SearchBar';
import NotificationRow from '../../common/tablerow/NotificationRow';
import TableComp from '../../common/tablecomp/TableComp';
import { useSelector } from 'react-redux';
import { addNotificationpi, deleteNotificationApi, editNotificationApi, getNotificationList } from '../../api';
import { toast } from 'react-toastify';
import Loader from '../../common/loader/Loader';
import alertDialog from '../../utils/alertDialog';

const PushNotification = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const [showCreateNotification, setShowCreateNotification] = useState(false);


  const [notificationData,setNotificationData]=useState([])
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPage, setTotalPage] = useState(0);
  const [loading,setLoading]=useState(false)

  const [editNotificationData, setEditNotificationData] = useState({});

  const handleCreateNotification = () => {
    setShowCreateNotification(true);
  };



  const handleEditNotification = (notificationData) => {
    setEditNotificationData(notificationData);
    setShowCreateNotification(true);
  };

  const fetchNotification=async()=>{
    try {
      setLoading(true);
      const response=await getNotificationList({token})
      // console.log("gh",response);
      setNotificationData(response)
       setLoading(false);
    } catch (error) {
      setLoading(false)
      const errorMessage = error?.data?.errors;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  }

  useEffect(()=>{
    fetchNotification()
  },[])


 

const handleDeleteNotification=async(id)=>{
  const confirmed = await alertDialog({
    title: 'Confirm Action',
    description: 'Are you sure you want to Delete this Notification?'
  });
  if(!confirmed){
    return
  }
  try {
    const response=await deleteNotificationApi({token,id})
    toast.success(response?.message)
    // setChange(!change)
    fetchNotification()
  } catch (error) { 
    const errorMessage = error?.data?.errors;
    if (Array.isArray(errorMessage)) {
      errorMessage.forEach((msg) => toast.error(msg));
    } else if (errorMessage) {
      toast.error(errorMessage);
    }
  }
}

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/settings" className="All-color-breakcurm">
      Settings
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Push Notification</span>,
  ];

  const styles={
    width:"275px"
  }
  const titles = [
    { title: "Notification Title" },
    { title: "Category" },
    { title: "Description" },
    { title: "Push Date" },
    { title: "Notification send to" },
    { title: "Action" },
  ];

  return (
    <div>
    {loading ? (
      <Loader/>
    ):(
      <>
        <div className="bread-crum">
          {breadcrumbItems.map((item, index) => (
            <div className="all-breakcrum" key={index}>
              {item}
              {index < breadcrumbItems.length - 1 && " > "}
            </div>
          ))}
        </div>
        {!showCreateNotification ? (

          <div className='whole-card-wrapper-notification'>
          <TableCard >
            <div className='push-notification-txt'>All Push Notification</div>

            <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',alignItems:'center',padding:'20px',gap:"10px"}}>

            <div><SearchBar style={styles}/></div>


              <div> <Button
                onClick={handleCreateNotification}
                variant="contained"
                className="create-admin-btn"
                startIcon={<AddCircleOutlineOutlinedIcon className="create-admin-icon" />}
              >
                Create New Notification
              </Button>
              </div>


            </div>

            <TableComp title={titles}>
              {notificationData.length >0 ? (
                  notificationData.map((item,idx)=>(
                    <NotificationRow 
                    key={item?._id} 
                    index={idx}
                     row={item}
                     deleteNotification={()=>handleDeleteNotification(item?._id)}
                     edit={()=>handleEditNotification(item)}
                     />
                  ))
              ):(
                <TableRow>
                <TableCell colSpan={titles.length} align="center" sx={{fontFamily:'Rosario',fontWeight:"600"}}>
                  No Data Found
                </TableCell>
              </TableRow>
              )}
          </TableComp>


          </TableCard>

          </div>
        ) : (

          <CreateNotification eData={editNotificationData}/>
        )}
      </>
)}


    </div>
  );
};

export default PushNotification;
